<script>
/**
 * Activity component
 */
export default {
  props: {
    title: {
      type: String,
      default: "Activity",
    },
    activityData: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
};
</script>

<template>
  <div class="card p-3">
    <div class="card-header text-white" style="background-color: #1a3562;">
      <div class="card-title">{{ title }}</div>
    </div>
    <div class="card-body">
      <ol class="activity-feed">
        <li
          class="feed-item"
          v-for="activity in activityData"
          :key="activity.id"
        >
          <div class="feed-item-list">
            <span class="date">{{ activity.date }}</span>
            <span class="activity-text">{{ activity.text }}</span>
          </div>
        </li>
      </ol>
    </div>
    <!-- end card-body -->
  </div>
  <!-- end card -->
</template>
