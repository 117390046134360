const menuItems = [
  {
    id: 1,
    label: "Main",
    isTitle: true,
  },
  {
    id: 2,
    label: "Dashboard",
    icon: "ti-home",
    badge: {
      variant: "primary",
      text: "2",
    },
    link: "/",
  },
  {
    id: 3,
    label: "Calendar",
    icon: "ti-calendar",
    link: "/calendar",
  },
  {
    id: 4,
    label: "Email",
    icon: "ti-email",
    subItems: [
      {
        id: 5,
        label: "Inbox",
        link: "/email/inbox",
      },
      {
        id: 6,
        label: "Email Read",
        link: "/email/read-email",
      },
      {
        id: 7,
        label: "Email Compose",
        link: "/email/compose",
      },
    ],
  },
];

export { menuItems };
