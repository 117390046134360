<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import { orderMethods, orderComputed } from "@/state/helpers";
import Activity from "@/components/widgets/activity";

export default {
  page: {
    title: "ExpressVN",
    meta: [{ name: "Tra cứu", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Activity,
  },
  computed: {
    ...orderComputed,
    handleTracking() {
      console.log("zô lại child");
      if (this.reactIsTracking) {
        console.log("tracking");
      }
    },
  },
  data() {
    return {
      title: "",
      items: [],
      orderLogData: [],
      isLoading: false,
    };
  },

  methods: {
    ...orderMethods,
    async getDataTracking(orderCode) {
      console.log("zô tracking page", orderCode);
      this.isLoading = true;
      let queryString = ``;
      if (orderCode) {
        queryString += `?code=${orderCode}`;
      }
      await this.trackingOrder(queryString);
      if (this.orderTrackingData) {
        this.orderLogData = [];
        for (let order of this.orderTrackingData.reference_log_list) {
          this.orderLogData.push({
            date: order.created_date,
            text: order.content,
          });
        }
      } else {
        this.orderLogData = [];
      }
      this.isLoading = false;
    },
    async initial() {},
  },
  async mounted() {},
};
</script>

<template>
  <Layout @tracking="getDataTracking">
    <vue-snotify></vue-snotify>
    <!-- <PageHeader:title="title":items="items" /> -->
    <div class="row">
      <div class="d-flex justify-content-center">
        <div class="col-xl-12 mt-5" v-if="!this.isLoading">
          <div class="row" v-if="!this.orderTrackingData">
            <div class="d-flex justify-content-center">
              <div class="col-9">
                <span>
                  <img
                    src="@/assets/images/notdata.png"
                    alt
                    style="width: 100%;height: 70%;object-fit: contain;"
                  />
                </span>
                <div class="description text-center mt-3">
                  <h1>Không tìm thấy đơn hàng</h1>
                  <span class="font-size-16">
                    Chúng tôi không tìm thấy mã đơn hàng của bạn trong hệ thống.
                    Vui lòng kiểm tra lại mã đơn hàng.</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div
              v-if="
                this.orderTrackingData.reference_log_list &&
                  this.orderTrackingData.order
              "
              class="d-flex justify-content-center"
            >
              <div class="col-9">
                <div
                  class="card p-3"
                  style="box-shadow:rgba(95, 95, 99, 0.44) 0px 21px 18px -8px !important"
                >
                  <div class="row">
                    <div class=" col-lg-4 col-md-12 col-sm-12">
                      <div class="card">
                        <div
                          class="card-header text-white"
                          style="background-color: #1a3562;"
                        >
                          <div class="card-title">Thông tin đơn hàng</div>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-5">
                              <span>Mã đơn hàng: </span>
                            </div>
                            <div class="col-7  d-flex justify-content-end">
                              <span>{{ this.orderTrackingData.order.id }}</span>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-5">
                              <span>Trạng thái hiện tại: </span>
                            </div>
                            <div
                              class="col-7 d-flex justify-content-end"
                              style="align-items: center"
                            >
                              <span
                                class="badge  font-size-15 "
                                style="background-color: #1a3562;"
                              >
                                {{
                                  this.orderTrackingData.reference_log_list[0]
                                    .status_text
                                }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class=" col-lg-4 col-md-12 col-sm-12">
                      <div class="card">
                        <div
                          class="card-header text-white"
                          style="background-color: #1a3562;"
                        >
                          <div class="card-title">Thông tin chi tiết</div>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-4">
                              <span>Sản phẩm: </span>
                            </div>
                            <div class="col-8 d-flex justify-content-end">
                              <span>{{
                                this.orderTrackingData.order.product_name
                              }}</span>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-4">
                              <span>Cân nặng: </span>
                            </div>
                            <div class="col-8 d-flex justify-content-end">
                              <span
                                >{{
                                  this.orderTrackingData.order.weight
                                }}
                                Kg</span
                              >
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-4">
                              <span>Ghi chú: </span>
                            </div>
                            <div class="col-8 d-flex justify-content-end">
                              <span>{{
                                this.orderTrackingData.order.note
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class=" col-lg-4 col-md-12 col-sm-12">
                      <div class="card">
                        <div
                          class="card-header text-white"
                          style="background-color: #1a3562;"
                        >
                          <div class="card-title">Người nhận</div>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-5">
                              <span>Tên người nhận: </span>
                            </div>
                            <div class="col-7 d-flex justify-content-end">
                              <span>{{
                                this.orderTrackingData.order.receiver_name
                              }}</span>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-4">
                              <span>Điện thoại: </span>
                            </div>
                            <div class="col-8 d-flex justify-content-end">
                              <span>{{
                                this.orderTrackingData.order.receiver_phone
                              }}</span>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-4">
                              <span>Địa chỉ: </span>
                            </div>
                            <div class="col-8 d-flex justify-content-end">
                              <span
                                >{{
                                  this.orderTrackingData.order
                                    .receiver_ward_name
                                }},
                                {{
                                  this.orderTrackingData.order
                                    .receiver_district_name
                                }},
                                {{
                                  this.orderTrackingData.order
                                    .receiver_city_name
                                }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <template>
                  <div
                    class=""
                    style="box-shadow:rgba(95, 95, 99, 0.44) 0px 21px 18px -8px !important"
                  >
                    <Activity
                      title="Lịch sử đơn hàng"
                      :activityData="orderLogData"
                    />
                  </div>
                </template>
              </div>
            </div>
          </div>
          <!-- end row -->
        </div>
        <div v-else style="position: absolute;top: 50%;">
          <b-spinner
            type="grow"
            label="Spinning"
            style="background-color: #1a3562;"
            class="m-1"
          ></b-spinner>
        </div>

        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
