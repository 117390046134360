<script>
import { layoutComputed } from "@/state/helpers";

import Vertical from "@/router/layouts/vertical";
import Horizontal from "@/router/layouts/horizontal";

export default {
  components: { Vertical, Horizontal },
  computed: {
    ...layoutComputed,
  },
  methods: {
    async trackingOrder(orderCode) {
      console.log("zô main", orderCode);
      await this.$emit("tracking", orderCode);
    },
  },
};
</script>

<template>
  <div>
    <Vertical
      @tracking="trackingOrder"
      v-if="layoutType === 'vertical'"
      :layout="layoutType"
    >
      <slot />
    </Vertical>

    <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
      <slot />
    </Horizontal>
  </div>
</template>
