<script>
/**
 * Topbar component
 */ import {
  notifyComputed,
  notificationMethods,
  orderMutations,
} from "@/state/helpers";

export default {
  data() {
    return {
      orderCode: "",
    };
  },

  computed: {
    ...notifyComputed,
  },
  methods: {
    clearNotification: notificationMethods.clear,
    ...orderMutations,
    ...notificationMethods,
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    async handleTrackOrd() {
      if (!this.orderCode) {
        this.error("Chưa nhập mã đơn hàng");
        this.resetDataTracking();
      } else {
        await this.$emit("tracking", this.orderCode);
      }
    },
  },
};
</script>
<template>
  <header id="page-topbar">
    <!-- <div class="navbar-header">
      <div class="col-12">
        <div class="d-flex justify-content-center">
          <div class="col-8">
            <div class="row">
              <div class="logo-container col-2">
                <img
                  src="@/assets/logo-main.png"
                  alt
                  style="width: 71%; height: 89%;object-fit: contain;"
                />
              </div>
              <div class="search-container col-10">
                <div class="row">
                  <div class="col-10">
                    <input
                      class="form-control"
                      placeholder="Nhập mã đơn hàng để tìm kiếm"
                      type="text"
                      name=""
                      id=""
                    />
                  </div>
                  <span class="btn btn-outline-primary col-2"> Tìm kiếm</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="navbar-header bg-white">
      <div class="d-flex"></div>

      <div class="d-flex col-9">
        <!-- LOGO -->
        <div
          class="navbar-brand-box"
          style="background-color: transparent !important;"
        >
          <a href="/" class="logo">
            <span class="logo-sm">
              <img src="@/assets/images/logo-main-sm.png" alt height="70" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-main.png" alt height="70" />
            </span>
          </a>
        </div>
        <!-- App Search-->
        <div class="ms-5 app-search d-lg-block" style="width: 60%;">
          <div class="position-relative">
            <input
              @keyup.enter="handleTrackOrd()"
              v-model="orderCode"
              type="text"
              class="form-control"
              placeholder="Nhập mã đơn hàng để tìm kiếm"
            />
            <span @click="handleTrackOrd()" class="fa fa-search"></span>
          </div>
        </div>
      </div>
      <div class="d-flex"></div>
    </div>
  </header>
</template>
